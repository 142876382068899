import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { motion } from 'framer-motion';
import RevealWord from '../RevealWord';

const Nav = styled(motion.nav)`
  position: fixed;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: row wrap;
  z-index: 4;

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const Menu = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0;
  list-style: none;
`;

const ItemWrapper = styled(motion.a)`
  font-size: 12px;
  margin-bottom: 40px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
`;

const Index = styled.div`
  transform-origin: 50%;
  transform: rotate(-90deg);
  margin-right: 32px;
  color: #74686e;

  div {
    color: #74686e;
  }
`;

const Label = styled.div`
  position: relative;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  max-width: 200px;

  * {
    color: #bababa;
  }
`;

const Line = styled(motion.div)`
  width: 50px;
  height: 1px;
  background: #bababa;
  display: flex;
  flex-flow: row wrap;
  margin-right: 6px;
`;

const Item = ({ children, index, to, active, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <ItemWrapper
      onTap={onClick}
      active={active}
      href={to}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      <Index>
        <RevealWord delay={+index * 0.2}>{index}</RevealWord>
      </Index>
      <Line
        initial={{ width: 0 }}
        animate={{
          width: active ? 40 : hovered ? 20 : 0,
          transition: { bounce: 0.2 },
        }}
      />
      <Label>{children}</Label>
    </ItemWrapper>
  );
};

export default ({ items, selectedItem, isVideoOpen }) => {
  const [active, setActive] = useState(selectedItem?.id);

  return (
    !isVideoOpen && (
      <Nav>
        <Menu>
          {items.map((item) => (
            <Item
              index={`${item.id}`.padStart(2, '0')}
              to={item.href}
              key={item.id}
              onClick={(e) => {
                setActive(item.id);
                if (item.onClick) {
                  e.preventDefault();
                  item.onClick();
                  return false;
                }
              }}
              active={active === item.id}
            >
              <RevealWord delay={0.2 * item.id}>{item.title}</RevealWord>
            </Item>
          ))}
        </Menu>
      </Nav>
    )
  );
};
