import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 100px 0;

  ${media.lessThan("medium")`
    padding: 32px 0; 
  `}

  p {
    line-height: 32px;

    ${media.lessThan("medium")`
      font-size: 12px;
      line-height: 24px;
    `}
  }
`;

const Container = styled.main`
  width: 90%;
  max-width: 1650px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const Block = styled.div`
  :nth-child(2) {
    p {
      text-align: right;

      ${media.lessThan("medium")`
        width: 100%;
        text-align: left;
        margin-top: 12px;
      `}
    }
  }
`;

const Whatsapp = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #25d366;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: 999999999;

  svg {
    fill: white;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Block>
          <p>
            Av. Itália, 277, sala 805 - São Pelegrino, Caxias do Sul - RS
          </p>
        </Block>

        <Block>
          <p>falecom@lab721.com.br</p>
          <p>+55 54 9.8139 9232</p>
        </Block>
      </Container>
    </Wrapper>
  );
};

export default Footer;
