import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import Footer from '../Footer';
import {Script} from 'gatsby'

const Page = ({
  children,
  title = '',
  description = '',
  meta = '',
  removeFooter,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    window.smartlook||(function(d) {
      let o=window.smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      let c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    window.smartlook('init', '05db2e8f1542a9cd42d0a62456d496f7a8221a8f', { region: 'eu' });
  })
  return (
    <>
      <Helmet>
        <title>Lab721{title.length > 0 && ` - ${title}`}</title>
        <meta name="description" content={description} />
        <link
          rel="canonical"
          href="https://fervent-jang-a90fde.netlify.app"
        ></link>
        <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/e73c9f70-951b-4137-9914-4ec38cb8322d-loader.js" ></script>
      </Helmet>
      {children}
      {!removeFooter && <Footer />}
    </>
  );
};

export default Page;
